<template>
<div>
    <div class="my-6 flex items-center justify-between">
    <div>
      <h3 class="font-bold">
        Edit category > <span class="text-primary">{{ data.name }}</span>
      </h3>

      <span class="text-sm text-gray-500"
        >Verify the following information and click the <b>save</b> button to confirm changes.
      </span>
    </div>
    <div class="flex justify-end">
      <Button @click="edit" color="primary" text="Save" />
    </div>
  </div>

  <div class="card shadow-sm bg-white border p-8">
    <span class="lg:inline-grid w-full grid-cols-2 gap-x-4 mb-6">
      <div class="flex-1 mr-2 mb-3">
        <Input
          v-model="data.name"
          type="default"
          label="Name"
          placeholder="Category Name"
        />
      </div>
    </span>
  </div>
</div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import {isEmpty } from "../../services/validate";
import { GET, PATCH, PUT } from "../../services/api";
import { inject, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "editLabel",
  components: {
    Input,
    Button,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const loader = inject("loading");
    const alert = inject("alert");
    const modal = inject("modal")
    const data = ref({});

    onMounted(async () => {
      loader.open();
      try {
        const response = await GET(`categories/${route.params.id}`);

        data.value = response.data

        loader.close();
      } catch(e) {
        loader.close();
        if(e.includes("Token") || e.includes("t_dynamik") || e == "Unauthorized") {
          localStorage.removeItem("t_dynamik");
          localStorage.removeItem("info_dynamik");
          router.push({ path: '/login'});
        }
        alert.open("Error", e, "danger");
      }
    });

    const edit = async () => {
      const obj = {
        name: data.value.name, 
        };
      console.log(obj);

      const validate = isEmpty(obj);
      if (validate) {
        validate && alert.open("Attention!", validate, "warning");
      } else {
        modal.open("Attention", "Do you really wish to edit this category?", "warning", "Yes" , async () => {
          try {
          loader.open();

          const response = await PATCH(`categories/${route.params.id}`, obj);
          alert.open(
            "Success!",
            `Category was edited successfully!`,
            "success"
          );
          loader.close();
          router.push("/categories");
        } catch (e) {
          loader.close();
          if(e.includes("Token") || e.includes("t_dynamik") || e == "Unauthorized") {
            localStorage.removeItem("t_dynamik");
            localStorage.removeItem("info_dynamik");
            router.push({ path: '/login'});
          }
          alert.open("Error", e, "danger");
        }
        },
        modal.close())
      }
    };

    return { edit, data };
  },
};
</script>
<style>
  .ck-content {
    font-family:Lato;
    line-height: 24px;
  }
  .ck-editor__editable_inline {
    min-height: 400px;
  }
  .ck-content h2 {
    font-size: 22px;
  }
  .ck-content h3 {
    font-size: 20px;
  }
  .ck-content h4 {
    font-size: 18px;
  }
  .conteudo-dica {
    font-family:Lato;
    line-height: 24px;
  }
  .conteudo-dica h2 {
    font-size: 22px;
  }
  .conteudo-dica h3 {
    font-size: 20px;
  }
  .conteudo-dica h4 {
    font-size: 18px;
  }
  figure img {
    margin: 0 auto;
  }
</style>